<template>
  <div id="app" :class="{ scrollLock: isScrollLocked }">
      <router-view @toggleScroll="scrollLock" />
  </div>
</template>
<script>
export default {
  name: 'App',
  components: {
  },
  data() {
    return {
      isScrollLocked: false,
    }
  },
  watch: {
    $route(to) {
      this.setMeta(to.meta);
    },
  },
  created(){
  },
  methods: {
    setMeta(meta) {
      document.title = meta.title;
      document
        .querySelector('meta[name="keywords"]')
        .setAttribute("content", meta.keywords);
      document
        .querySelector('meta[name="description"]')
        .setAttribute("content", meta.description);
    },
  scrollLock() {
      this.isScrollLocked = !this.isScrollLocked;
    },
  }
}
</script>
<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap');

body
{
  padding: 0;
  margin: 0;
  font-size: 16px;
  overflow-X: hidden;
  scroll-behavior: smooth;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif !important;
}

a {
  color: inherit;
  text-decoration: inherit; /* no underline */
}

.scrollLock {
  max-width: 100vw;
  max-height: 100vh;
  overflow-x: hidden !important;
  overflow-y: hidden !important;
}


</style>
