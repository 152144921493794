import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);


export const vuexStorage = new Vuex.Store({
    state: {
        cart: [],
        promoCode: '',
        clientData: {
            clientName: '',
            companyName: '',
            companyID: '',
            mail: '',
            phone: ''
        },
        deliveryData: {
            address: '',
            houseNum: '',
            city: '',
            postalCode: ''
        },
        paymentType: ''
    },
    mutations: {
        reset(state) {
            state.cart = []
            state.promoCode = ''
            state.clientData = 
            {
                clientName: '',
                companyName: '',
                companyID: '',
                mail: '',
                phone: ''
            }
            state.deliveryData = {
                address: '',
                houseNum: '',
                city: '',
                postalCode: ''
            }
        return state;
        },
        clearCart(state)
        {
            state.cart = [];
            return state;
        },
        clearCode(state)
        {
            state.promoCode = '';
            return state;
        },
        delete(state, index) {
            state.cart.splice(index, 1);
        return state;   
        },
        add(state, item) {
        state.cart.push(item)
        return state;
        },
        addCode(state, code) {
            state.promoCode = code
            return state;
            },
        changeQuantity(state, info) {
            state.cart[info.indx].quantity = info.amount;
            return state;
        },
        createClientData(state, clientData)
        {
            state.clientData = 
            {
                clientName: clientData.name,
                companyName: clientData.companyName,
                companyID: clientData.companyNum,
                mail: clientData.mail,
                phone: clientData.phone
            }
            state.deliveryData = {
                address: clientData.street,
                houseNum: clientData.houseNum,
                city: clientData.city,
                postalCode: clientData.zipCode
            }
            return state;
        },
    },
    plugins: [createPersistedState()]

})