import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/functions';

  // Your web app's Firebase configuration
  // For Firebase JS SDK v7.20.0 and later, measurementId is optional
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBZWNSTVZZo7iWkEH9kjX1QUQ36AZbDHDo",
  authDomain: "kompreshop.firebaseapp.com",
  projectId: "kompreshop",
  storageBucket: "kompreshop.appspot.com",
  messagingSenderId: "814363258420",
  appId: "1:814363258420:web:cc670cf0857ec60e732461",
  measurementId: "G-2MQQJJ6HSJ"
};
  // Initialize Firebase
firebase.initializeApp(firebaseConfig);
  
//const db = firebase.firestore()
const auth = firebase.auth();
const db = firebase.firestore();
const storage = firebase.storage();
const functions = firebase.functions();


// uncomment when firestore emulator is fired up

// if (location.hostname === "localhost") {
//   db.settings({
//     host: "localhost:8080",
//     ssl: false
//   });
//   db.useEmulator("localhost", 8080);
//   functions.useEmulator("localhost", 5001);
// }

export { firebase, auth, db, storage, functions };